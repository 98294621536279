<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Empleado"
                    :loading="loadingEmployees"
                    :valueInput="values.employee_id"
                    :rule="rules.required"
                    :items="serverItems.employees"
                    :errorMessages="serverErrors.employee_id"
                    @valueChange="(v) => {values.employee_id = v; delete serverErrors.employee_id}"
                    data-cy="employee_id-input"
                    :disabled="edit"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

       
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.email = v; delete serverErrors.email; delete serverErrors.slug}" 
                    :valueInput="values.email" 
                    :rule="[...rules.required,...rules.email]" 
                    label="Correo Electrónico"
                    :errorMessages="serverErrors.email||serverErrors.slug"
                    data-cy="email-input"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row v-if="!edit">
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.password = v; delete serverErrors.password}" 
                    :valueInput="values.password" 
                    :rule="[...rules.required, ...rules.length8]" 
                    label="Contraseña"
                    :errorMessages="serverErrors.password"
                    :count="true"
                    :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    @clickAppend="() => {showPass = !showPass}"
                    data-cy="password-input"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row v-if="!edit">
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.password_confirmation = v; delete serverErrors.password_confirmation}" 
                    :valueInput="values.password_confirmation" 
                    :rule="[...rules.required, ...rules.samePassword, ...rules.length8]" 
                    label="Confirmar contraseña"
                    :errorMessages="serverErrors.password_confirmation"
                    :count="true"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                    data-cy="password_confirmation-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <switchForm
                    title="Estatus"
                    leftLabel=""
                    rightLabel="Activo"
                    :valueInput="values.status"
                    data-cy="status-input"
                    @valueChange="(v) => {values.status = v}"
                    
                ></switchForm>
            </v-col>
        </v-row>

       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingEmployees:false,
            showPass: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                phone: [
                    v => !!v || 'El campo ES REQUERIDO',
                    v => /^[0-9]*$/.test(v) || 'El campo SOLO DEBE CONTENER NÚMEROS',
                    v => v.length === 10 || 'El campo DEBE CONTENER 10 DIGITOS'
                ],
                email: [
                    v => (v.length == 0 || /.+@.+\..+/.test(v)) || 'El campo DEBE SER CON FORMATO VÁLIDO',
                ],
                samePassword:[
                    (v) => v == this.values.password || "Las contraseñas no coinciden",
                ],
                length8:[
                    v => !!v || 'El campo requerido',
                    v => (!!v && v.length >= 8) || 'Al menos de 8 caracteres'
                ],

            },
            serverItems:{
                employees:[]
            }
            

          
        }
    },
    watch: {
        ["values.employee_id"]: function (v) {
            if(v != ""){
                const found = this.serverItems.employees.find((item) => item.id == v)
                if(found != undefined){
                    this.values.email = found.email;
                    return;
                }
            }
            this.values.email = "";
        },

        
        
    },
    mounted(){
        this.getEmployees();
    },
    methods: {
        getEmployees()
        {
            this.loadingEmployees = true;
            if(this.edit){
                this.$api.employee.list()
                    .then((resp) => {
                        this.serverItems.employees = resp.data.map((item) => {
                            return {text: item.name, value:item.id, ...item};
                        });
                    
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loadingEmployees = false;
                    })
                ;
                return;
            }
            this.$api.employee.getWithoutUser()
                .then((resp) => {
                    this.serverItems.employees = resp.data.map((item) => {
                        return {text: item.name, value:item.id, ...item};
                    });
                   
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingEmployees = false;
                })
            ;
        },

       
    }
}
</script>

<style>

</style>